
//export const server = 'https://bloarum.net/portfolio/data' //simulate API with static JSON
export const server = 'https://leenajade.com/data'
//export const server = "https://localhost:44307/API"; //IIS Express
//export const server = "http://localhost:2021/ASPNET/api"; //SIT
//export const server = "http://localhost:4430"; //IIS Portfolio
//export const server = "https://bloarum.net/api";

//export const publicPath = '/beta/';
export const publicPath = '/portfolio'; //live and IISExpress
//export const publicPath = '/';




export const locale = 'en-US';