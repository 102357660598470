

export function Summary() {
    return (
        <>
            <h2>Summary</h2>
            <hr />
            <div className='container'>
                <p className='summaryText'>
                    Full Stack Software Developer specializing in web application development. Experienced with all stages of the development cycle for dynamic web projects. Well-versed in numerous programming languages including C#.NET, VB.NET, and JavaScript/TypeScript. Strong background in both mechanical design and software development. Mitigated the gap between end users and developers, co-designed and built scalable solutions.
                </p>
            </div>
        </>
        );
}