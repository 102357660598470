import { Education } from "./Education";
import { Experience } from "./Experience";
import { Skills } from "./Skills";
import { Summary } from "./Summary";

export function Overview() {
    return (<div>
        <Summary />
        <Skills />
        <Experience />
        <Education />
    </div>);
}